import happycat from '../Assets/happycat.png'

function Yippee(){
  return (
    <div>
      <h1 style={{ color: 'white' }}>YIPPEEEEE</h1>
      <img src={happycat} />
    </div>
  )
}

export default Yippee