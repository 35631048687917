import React, { useRef, useState } from 'react';
import './heartcss.css';
import Yippee from './yippee';

function Heart() {
    const [yes, setYes] = useState(false);
    const noButtonRef = useRef()

    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    function handleNoHover() {
        const noButton = noButtonRef.current;
        if (noButton) {
            const maxX = window.innerWidth - noButton.offsetWidth;
            const maxY = window.innerHeight - noButton.offsetHeight;
            const randomX = getRandomInt(maxX);
            const randomY = getRandomInt(maxY);
            noButton.style.position = 'absolute';
            noButton.style.left = `${randomX}px`;
            noButton.style.top = `${randomY}px`;
        }
    }

    function no(){
        alert("BITCH.")
    }

    if (yes) {
        return (
            <Yippee />
        )
    }

    return (
        
        <div >
            <header className='unselectable' style={{ color: 'white' }}>
                Gabby, will you be my Valentine?
            </header>
            <div className='btn-holder'>
                <button className='btn' onClick={() => setYes(true)}>
                    <svg width="180px" height="60px" viewBox="0 0 180 60" className="border">
                    <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
                    <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
                    </svg>
                    <span className='unselectable'>Yes</span>
                </button>
                <button className='btn' onClick={no} onMouseOver={handleNoHover} ref={noButtonRef}><svg width="180px" height="60px" viewBox="0 0 180 60" className="border">
                    <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
                    <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
                    </svg>
                    <span className='unselectable'>No</span>
                </button>
            </div>
            
        </div>
    )


};


export default Heart;