import './App.css';
import Heart from './Components/heart';
function App() {
  return (
    <div className="App">
      <Heart></Heart>
    </div>
  );
}

export default App;
